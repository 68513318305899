<template>
  <div class="whitelist-page">
    <div class="public-breadcrumb">
      <router-link style="color: #6e6b7b" to="/">{{
        $t("devices.Welcome")
      }}</router-link>
      > {{ $t("devices.Devices") }} >
      <span style="color: #3461ff">{{ $t("devices.WhiteList") }}</span>
    </div>
    <div class="flex-between card-bg whitelist-page_wrapper-btn">
      <el-button
        @click="handelDeleteAll"
        class="danger-btn btn"
        icon="el-icon-delete"
        size="small"
        >{{ $t("devices.whiteList.batchDelete") }}</el-button
      >
      <div class="right-btn">
        <el-button size="small" @click="handelImport" class="gray-btn btn"
          >{{ $t("devices.import") }}
          <!-- <i class="el-icon-arrow-down el-icon--right"></i> -->
        </el-button>
        <el-button size="small" @click="handelExport" class="gray-btn btn"
          >{{ $t("devices.export") }}
          <!-- <i class="el-icon-arrow-down el-icon--right"></i> -->
        </el-button>
      </div>
    </div>
    <div class="whitelist-page_wrapper-content card-bg">
      <div
        class="whitelist-page_wrapper-content_fliter flex_ flex-column-center"
      >
        <span class="records-text"
          >{{ $t("devices.records") }}: {{ pageParams.total }}</span
        >
        <div class="serach-input flex-between">
          <el-input
            :placeholder="$t('devices.whiteList.whiteListInputBarcode')"
            prefix-icon="el-icon-search"
            v-model="pageParams.tagIdentity"
            @input="getList"
            clearable
          >
          </el-input>
          <el-button :loading="loading" @click="handelSave" class="save-btn">{{
            $t("devices.save")
          }}</el-button>
        </div>
        <div class="whitelist-page_wrapper-content_fliter-status">
          <span>{{ $t("devices.status") }}</span>
          <el-switch
            v-model="storeEnable"
            active-color="rgba(52, 97, 255, 1)"
            style="float: right"
            @change="updateWhiteListEnable"
          >
          </el-switch>
        </div>
      </div>
      <div class="whitelist-page_wrapper-content-list">
        <el-row :gutter="32" style="margin-bottom: 20px">
          <div class="empty" v-if="!list.length">
            {{ $t("devices.noData") }}
          </div>
          <el-col
            v-for="(item, i) in list"
            :key="i"
            :md="12"
            :lg="8"
            :xl="6"
            class="paddinng_l0"
          >
            <div
              class="item"
              :class="{ 'item-checked': item.check }"
              @click="handelCheck(i, item)"
            >
              {{ item.tagIdentity }}
            </div>
          </el-col>
        </el-row>
        <pagination :tableInfo="pageParams" @changePage="changePage" />
      </div>
    </div>

    <ImportDialog
      v-if="isVisibleImportDialog"
      :importType="'excel'"
      :acceptFileType="'.xls,.xlsx'"
      :isVisible="isVisibleImportDialog"
      :importTypeList="ImportTypeList"
      @handelSubmit="handelSubmitImport"
      @handelCancel="isVisibleImportDialog = false"
    />
    <ExportDialog
      v-if="isVisibleExportDialog"
      :isVisible="isVisibleExportDialog"
      @handelCancel="isVisibleExportDialog = false"
      @handelSubmit="handelSubmitExport"
      :exportTypeList="exportTypeList"
      :exportType="2501"
    />

    <confirm-dialog
      :dialogVisible="isVisibleConfirmDialog"
      @handleClose="handelConfirmDialog"
      :confirmObj="confirmObj"
    >
    </confirm-dialog>

    <!-- <table id="tablelist" style="display: none">
      <tr>
        <th>tagIdentity</th>
      </tr>
      <tr v-for="(item, i) in tablelist" :key="i">
        <td>{{ item.tagIdentity }}</td>
      </tr>
    </table> -->
  </div>
</template>

<script>
import {
  whiteList,
  whiteListsaveadd,
  whiteListbatchPhysicalDelete,
  saveTagInfoByExcel,
  exportExcel,
  getWhiteListEnable,
  updateWhiteListEnable,
} from "@/libs/api/white-list";
import { debounce } from "lodash";
import ImportDialog from "@/components/ImportDialog/indexSingleFile";
import ExportDialog from "@/components/ExportDialog";
import ConfirmDialog from "@/components/confirm-dialog/index.vue";
// import FileSaver from "file-saver";
// import XLSX from "xlsx";
import { downLoad } from "@/utils/common";
import pagination from "@/components/pagination";
import { commonStore } from "@/store/global-select-store";
export default {
  components: {
    ImportDialog,
    ExportDialog,
    pagination,
    ConfirmDialog,
  },
  data() {
    return {
      commonStore,
      ImportTypeList: [
        {
          label: 'excel',
          name: "Excel",
        },
      ],
      exportTypeList: [
        {
          label: 2501,
          name: "Excel",
        },
      ],
      pageParams: {
        current: 1,
        size: 10,
        total: 0,
        tagIdentity: "",
      },
      list: [],
      loading: false,
      ids: [],
      isVisibleImportDialog: false, // 导入
      isVisibleExportDialog: false, // 导出
      fileList: [],
      limitNum: 1,
      formData: {},

      // tablelist: [], //导出表格

      storeEnable: false,
      isVisibleConfirmDialog: false, //确认弹框
      confirmObj: {
        type: this.$t("devices.tips"),
        cancelText: this.$t("devices.cancel"),
        confirmText: this.$t("devices.sure"),
        content: this.$t("devices.whiteList.deleteWhihtListTips"),
      },

      storeStatusObj: {},
    };
  },
  methods: {
    changePage() {
      this.getList();
    },
    handelCheck(index, item) {
      item.check = !item.check;
      if (item.check) {
        this.ids.push(item.tagIdentity);
      } else {
        let idx = this.ids.indexOf(item.tagIdentity);
        this.ids.splice(idx, 1);
      }
      this.$forceUpdate();
    },
    handelConfirmDialog(val) {
      const { currentConfirmType } = this;
      if (val === "confirm") {
        if (currentConfirmType === "whiteListbatchPhysicalDelete") {
          //批量删除
          this.doDeleteAllConfirm();
        }

        if (currentConfirmType === "updateWhiteListEnable") {
          this.doUpdateWhiteListEnable();
        }
      } else {
        this.isVisibleConfirmDialog = false;
        if (currentConfirmType === "updateWhiteListEnable") {
          this.storeEnable = this.storeStatusObj.enable == 1 ? false : true;
        }
      }
    },
    // do  UpdateWhiteListEnable
    async doUpdateWhiteListEnable() {
      try {
        const { data } = await updateWhiteListEnable({
          storeId: localStorage.getItem("storeId"),
        });
        if (data.state === true) {
          this.isVisibleConfirmDialog = false;
          this.getWhiteListEnable();
        } else {
          this.$message.error(data.message);
          this.storeEnable = this.storeStatusObj.enable == 1 ? false : true;
        }
      } catch (err) {
        const { message } = err;
        this.$message.error(message);
        this.storeEnable = this.storeStatusObj.enable == 1 ? false : true;
      }
    },
    //do 批量删除
    async doDeleteAllConfirm() {
      try {
        const params = {
          storeId: localStorage.getItem("storeId"),
          tagIdentifies: this.ids.join(","),
        };
        const { data } = await whiteListbatchPhysicalDelete(params);
        if (data.state === true) {
          this.isVisibleConfirmDialog = false;
          this.$message.success(this.$t("devices.deleteSuccessTips"));
          this.pageParams.current = 1;
          this.getList();
          this.ids = [];
        } else {
          this.$message.error(data.message);
        }
      } catch (err) {
        const { message } = err;
        this.$message.error(message);
      }
    },
    handelDeleteAll() {
      if (!this.ids.length) {
        this.$message({
          message: this.$t("devices.selectedNoData"),
          type: "warning",
        });
        return;
      }
      this.confirmObj.content = this.$t(
        "devices.whiteList.deleteWhihtListTips"
      );
      this.currentConfirmType = "whiteListbatchPhysicalDelete";
      this.isVisibleConfirmDialog = true;
    },
    async handelSave() {
      if (!this.pageParams.tagIdentity) {
        this.$message({
          message: this.$t("devices.whiteList.emptySearchInputTips"),
          type: "warning",
        });
        return;
      }
      this.loading = true;
      try {
        const params = {
          storeId: localStorage.getItem("storeId"),
          tagIdentity: this.pageParams.tagIdentity,
        };
        const { data } = await whiteListsaveadd(params);
        if (data.state === true) {
          this.pageParams.current = 1;
          this.getList("add"); // add 为新增
          this.$message.success(this.$t("devices.saveSuccess"));
        } else {
          this.$message.error(data.message);
        }
        this.loading = false;
      } catch (err) {
        const { message } = err;
        this.$message.error(message);
        this.loading = false;
      }
    },
    getList: debounce(async function (flay) {
      const _this = this;
      const params = {
        storeId: localStorage.getItem("storeId"),
        tagIdentity: flay === "add" ? "" : _this.pageParams.tagIdentity, //价签条码
        current: _this.pageParams.current,
        size: _this.pageParams.size,
        orders: [],
      };

      try {
        const { data } = await whiteList(params);
        if (data.state === true) {
          let dataNew = data.data || {};
          _this.list = dataNew.records;
          _this.list.forEach((item) => {
            item.check = false;
          });
          _this.pageParams.total = dataNew.total || 0;
        } else {
          this.$message.error(data.message);
        }
      } catch (err) {
        const { message } = err;
        _this.$message.error(message);
      }
    }, 1000),
    handelImport() {
      this.formData = {};
      this.fileList = [];
      this.isVisibleImportDialog = true;
    },
    handelExport() {
      this.isVisibleExportDialog = true;
    },
    async handelSubmitImport(param) {
      param = param || {};
      try {
        const { data } = await saveTagInfoByExcel(param.formData);
        if (data.state === true) {
          this.$message.success(this.$t("devices.importSuccess"));
          this.isVisibleImportDialog = false;
          this.getList();
        } else {
          this.$message.error(data.message);
        }
      } catch (err) {
        const { message } = err;
        this.$message.error(message);
      }
    },
    handelCancelImport() {
      this.isVisibleImportDialog = false;
    },
    async handelSubmitExport(val) {
      // console.log(val, "valvalval");
      // this.$confirm(this.$t("devices.exportTips"), this.$t("devices.tips"), {
      //   confirmButtonText: this.$t("devices.yes"),
      //   cancelButtonText: this.$t("devices.no"),
      //   type: "warning",
      // }).then(async () => {
        const _this = this;
        const params = {
          storeId: localStorage.getItem("storeId"),
          tagIdentityList: this.ids,
        };

        try {
          // const { data } = await whiteList(params);
          let { data } = await exportExcel(params);
          if (data) {
            downLoad(data, this.$t("devices.priceSigningList"));
            _this.isVisibleExportDialog = false;
            _this.$message.success(this.$t("devices.exportSuccess"));
          } else {
            _this.$message.error(this.$t("devices.exportException"));
          }
          // if (data.state === true) {
          //   downLoad(data.data, '价签白名单列表');
          //   _this.isVisibleExportDialog = false;
          //   _this.$message.success("导出成功");
          // let dataNew = data.data || {};
          // this.tablelist = dataNew.records || [];
          // window.setTimeout(() => {
          //   var wb = XLSX.utils.table_to_book(
          //     document.querySelector("#tablelist"),
          //     { row: true }
          //   );
          //   var wbout = XLSX.write(wb, {
          //     bookType: "xlsx",
          //     bookSST: true,
          //     type: "array",
          //   });
          //   try {
          //     FileSaver.saveAs(
          //       new Blob([wbout], { type: "application/octet-stream" }),
          //       "价签白名单" + ".xlsx"
          //     );
          //     _this.isVisibleExportDialog = false;
          //     this.$message.success("导出成功");
          //   } catch (e) {
          //     if (typeof console !== "undefined") console.log(e, wbout);
          //   }
          // return wbout;
          // }, 1000);
          // } else {
          //   _this.$message.error(data.message);
          // }
        } catch (err) {
          const { message } = err;
          _this.$message.error(message);
        }
      // });
    },

    async getWhiteListEnable() {
      try {
        const { data } = await getWhiteListEnable({
          storeId: localStorage.getItem("storeId"),
        });
        if (data.state === true) {
          let dataNew = data.data || {};
          this.storeStatusObj = JSON.parse(JSON.stringify(dataNew));
          this.storeEnable = dataNew.enable == 1 ? false : true;
        } else {
          this.$message.error(data.message);
        }
      } catch (err) {
        const { message } = err;
        this.$message.error(message);
      }
    },
    updateWhiteListEnable() {
      const text =
        this.storeStatusObj.enable == 1
          ? this.$t("devices.whiteList.open")
          : this.$t("devices.whiteList.close");
      this.confirmObj.content = text;
      this.currentConfirmType = "updateWhiteListEnable";
      this.isVisibleConfirmDialog = true;
      /*const text =
        this.storeStatusObj.enable == 1
          ? this.$t("devices.whiteList.open")
          : this.$t("devices.whiteList.close");
      this.$confirm(`${text}`, this.$t("devices.tips"), {
        confirmButtonText: this.$t("devices.sure"),
        cancelButtonText: this.$t("devices.cancel"),
        type: "warning",
      })
        .then(async () => {
          try {
            const { data } = await updateWhiteListEnable({
              storeId: localStorage.getItem("storeId"),
            });
            if (data.state === true) {
              this.getWhiteListEnable();
            } else {
              this.$message.error(data.message);
            }
          } catch (err) {
            const { message } = err;
            this.$message.error(message);
          }
        })
        .catch(() => {
          this.storeEnable = this.storeStatusObj.enable == 1 ? false : true;
        });*/
    },
  },
  created() {
    
commonStore.setInjectCallBack(this.getList);
    this.getList();
    this.getWhiteListEnable();
  },
};
</script>

<style lang="scss" scoped>
.flex_ {
  display: flex;
}
.flex-column-center {
  align-items: center;
}
.flex-row-center {
  justify-content: center;
}
.whitelist-page {
  .card-bg {
    background: #ffffff;
    box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.05);
    border-radius: 6px;
  }
  .flex-between {
    display: flex;
    justify-content: space-between;
  }
  &_wrapper-btn ::v-deep {
    padding: 20px;
    margin-bottom: 20px;
    .danger-btn {
      border: none;
      background: #fc184d;
      color: #ffffff;
      // width: 140px;
      // padding: 0;
    }
    .gray-btn {
      background: #eeeeee;
      border: 1px solid #eeeeee;
      color: #666666;
      width: 80px;
    }
    .btn {
      // padding: 15px 20px;
      border-radius: 6px;
      // font-size: 16px;
      // height: 40px;
    }
  }
  &_wrapper-content {
    padding: 30px 35px;
    &_fliter {
      .records-text {
        font-size: 12px;
        // color: #999999;
      }
      &-status {
        font-size: 14px;
        color: #666666;
        span {
          margin-right: 15px;
        }
      }
    }
    .serach-input {
      width: 33%;
      margin: 0 auto;
      .save-btn {
        background: #3561ff;
        border-radius: 6px;
        // padding: 20px 23px;
        color: white;
        margin-left: 14px;
      }
    }
    &-list {
      //   padding: 0 15px 35px;
      .item {
        padding: 25px;
        background: #f3f3f3;
        border-radius: 6px;
        font-size: 22px;
        font-weight: 400;
        color: #666666;
        text-align: center;
        margin-top: 30px;
        cursor: pointer;
        border: 1px solid #f3f3f3;
      }
      .item-checked {
        background: #f4f4ff;
        border: 1px solid #3462fe;
        color: rgba(13, 110, 253, 1);
      }
    }
    .empty {
      text-align: center;
      margin: 90px 0;
    }
  }
}
</style>