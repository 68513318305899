import router from "../axios";

import config from "../../config/index"
const { baseUrl } = config;

function whiteList(data) {
    //获取白名单列表
    return router({
      url: baseUrl+"whiteList/v1/getList",
      data: data,
      method: "POST"
    });
  }
function whiteListsaveadd(data) {
    return router({
      url: baseUrl+"whiteList/v1/save",
      data: data,
      method: "POST",
    });
  }
  function whiteListbatchPhysicalDelete(data) {
    return router({
      url: baseUrl+"whiteList/v1/batchPhysicalDelete",
      data: data,
      method: "POST",
    });
  }
  function whiteListBatchDelete(data) {
    return router({
      url: baseUrl+"whiteList/v1/batchDelete",
      data: data,
      method: "POST",
    });
  }
  
//   通过Excel方式录入价签信息  
  function saveTagInfoByExcel(data) {
    //获取白名单列表
    return router({
      url: baseUrl+"whiteList/v1/saveTagInfoByExcel",
      data: data,
      method: "POST",
      headers:{
        "Content-Type": "multipart/form-data"
      }
    });
  }
  function exportExcel(data) {
    //获取白名单列表
    return router({
      url: baseUrl+"whiteList/v1/export",
      data: data,
      method: "POST",
      responseType: "blob"
    });
  }
  
  function getWhiteListEnable(data) {
    //查询门店白名单启用配
    return router({
      url: baseUrl+"whiteList/v1/getWhiteListEnable",
      data: data,
      method: "POST"
    });
  }
  function updateWhiteListEnable(data) {
    //修改门店白名单启用状态 
    return router({
      url: baseUrl+"whiteList/v1/updateWhiteListEnable",
      data: data,
      method: "POST"
    });
  }
  export {
    whiteList,
    whiteListsaveadd,
    whiteListbatchPhysicalDelete,
    saveTagInfoByExcel,
    exportExcel,
    getWhiteListEnable,
    updateWhiteListEnable,
    whiteListBatchDelete
    
}